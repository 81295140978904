import React from 'react';
import styles from './ChatBox.module.css';


interface ChatBoxProps {
  onPromptSelect?: (prompt: string) => void;
}

const ChatBox: React.FC<ChatBoxProps> = ({ onPromptSelect }) => {
  const prompts = [
    'What deduction and credits am I eligible for in 2023?',
    'How do recent changes in the tax law affect me or my business?',
    'How does the tax code define a "qualified business income deduction"?',
    'Can you explain the rules and benefits around "bonus depreciation"?'
  ];

  return (
    <>
      {prompts.map((prompt, index) => (
        
         <div 
         className={styles.chat_box} 
         onClick={() => onPromptSelect?.(prompt)} // Triggering the callback on click
       >
           <div>{prompt}</div>
       </div>
      ))}
    </>
  );
};

export default ChatBox;
